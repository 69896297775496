import Cookie from 'js-cookie'

export const state = () => ({
  current_user: null,
  advisor: null,
  calendars: [],
  calendarEvents: [],
  crumbs: []
})

export const mutations = {
  setCurrentUser (state, user) {
    state.current_user = user
  },
  setGoogleCalendars (state, calendars) {
    state.calendars = calendars
  },
  setAdvisor (state, user ) {
    state.advisor = user
  },
  setCalendarEvents (state, events) {
    state.calendarEvents = events
  },
  setCrumbs (state, crumbs) {
    state.crumbs = crumbs.filter(c => c)
  }
}

export const getters = {
  myCalendars (state) {
    return state.calendars
  },
  calendarEvents (state) {
    return state.calendarEvents
  }
}

export const actions = {
  login ({commit, dispatch}, {email, password}) {
    return this.$axios.post('/user/login', {email, password}, {baseURL: '/api'})
      .then( res => {
        const {user, token} = res.data

        commit('setCurrentUser', user)
        Cookie.set('access-token', token, {domain: process.env.host})

        if (process.env.env == 'dev') Cookie.set('access-token', token)

        this.$axios.setToken(token, 'Bearer')

        dispatch('advisor/get', null, {root: true})

        return res.data.user
      })
  },
  logout ({commit}) {
    commit('setCurrentUser', null)
    Cookie.remove('access-token', {domain: process.env.host})

    if (process.env.env == 'dev') Cookie.remove('access-token')

    this.$axios.setToken(false)
    return
  },
}