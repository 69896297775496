<template>
  <v-app>
    <v-navigation-drawer
        app
        v-model="drawer"
        fixed
        clipped
        dark
        width="216"
        id="main-menu"
    >
      <v-layout column fill-height>
        <v-spacer />
      </v-layout>

    </v-navigation-drawer>


    <v-toolbar app clipped-left clipped-right fixed dark class='primary elevation-2'>
      <v-toolbar-side-icon @click="drawer = !drawer" />

      <v-toolbar-title>
        <nuxt-link to="/">
          <!--<v-img
              :src="logo"
              height='36px'
              width='115px'
              contain
          />-->
        </nuxt-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <!-- TODO: Make this component accept the needed data -->

        <portal-target name="layout-toolbar-items" slim />
      </v-toolbar-items>

    </v-toolbar>

    <v-content>
      <nuxt />
    </v-content>

    <portal-target name="layout"/>
  </v-app>
</template>

<script>
  export default {
   components: {},

   data () {
     return {
       drawer: false,
       snackbar: false
     }
   }
 }
</script>
