import Cookie from 'js-cookie'

export const state = () => ({
  ambassadorContactId: null,
  ambassador: null
})

export const mutations = {
  setAmbassadorContactId (state, id) {
    state.ambassadorContactId = id
  },

  setAmbassador(state, ambassador) {
    state.ambassador = ambassador
  }
}

export const actions = {
  setAmbassador({commit, $dialog}, contactId) {
    return this.$axios
      .get(`/contacts/${contactId}`)
      .then(res => {
        commit('setAmbassadorContactId', contactId)
        commit('setAmbassador', res.data)
        Cookie.set('ambassadorContactId', contactId)

        this.$$dialog.message.info('Ambassador Loaded')
      })
      .catch(e => {
        this.$$dialog.error({
          text: 'There was an error with the ambassador id',
          title: 'Error Processing'
        })

        throw e
      })
  },

  logout ({commit}) {
    commit('setAmbassadorContactId', null)
    commit('setAmbassador', null)
    Cookie.remove('ambassadorContactId')

    this.$$dialog.message.info('Ambassador logged out')
  }
}
