import Vue from 'vue'
import Vuelidate from 'vuelidate'
import * as validators from 'vuelidate/lib/validators'

import ccValidator from 'card-validator'

const creditCard = (card) => {
  const test = ccValidator.number(card)

  return test.isValid
}

const bambeePhoneFormat = (phone) => phone ? phone.length == 17 : true
const bambeePhoneStrippedFormat = (phone) => phone ? phone.length == 10 : true

const install = {
  install (Vue) {
    validators.bambeePhoneFormat = bambeePhoneFormat
    validators.bambeePhoneStrippedFormat = bambeePhoneStrippedFormat
    validators.creditCard = creditCard

    Vue.prototype.$_validators = validators
  }
}

export default () => {
  Vue.use(Vuelidate)
  Vue.use(install)
}

