import Vue from 'vue'
import moment from 'moment'

import VueCurrencyFilter from 'vue-currency-filter'

export default () => {
  Vue.filter('capitalize', val => val ? val.toUpperCase() : null)
  Vue.filter('date', (val,format = 'M/D/YYYY') => val ? moment(val).format(format) : null )
  Vue.filter('time', (val,format = 'hh:mm a') => val ? moment(val).format(format) : null)
  Vue.filter('date-ago', (val) => val ? moment(val).fromNow() : null)
  Vue.filter('padzero', (val) => val <= 9999 ? `000${val}`.slice(-4) : val)  
}

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
})
