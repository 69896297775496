import Cookie from 'js-cookie'

export default async function ({app, store, route}) {
  const ambassadorContactId = Cookie.get('ambassadorContactId') || route.query.ambassadorContactId

  if (ambassadorContactId) await store.dispatch('ambassador/setAmbassador', ambassadorContactId)
    .catch(e => {
      store.dispatch('ambassador/logout')
    })

  /*app.$axios.setToken(token, 'Bearer')

  return app.$axios.get('/user/me')
    .then(res => {
      let user = res.data
      app.store.commit('setCurrentUser', user)
      app.store.dispatch('advisor/get')
    })
    .catch(err => {
      Cookie.remove('access-token')
      app.$axios.setToken(false)
    })*/
}