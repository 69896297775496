import Vue from 'vue'
import _ from 'lodash/core'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'

_.cloneDeep = cloneDeep
_.debounce = debounce


_.install = function (Vue, options) {
  Vue.prototype.$_ = _
}

export default (ctx, inject) => {
  Vue.use(_)
  ctx.app.$_ = _
  inject('$_', _)
}