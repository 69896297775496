import Vue from 'vue'
import Vuetify from 'vuetify'
import VuetifyToast from 'vuetify-toast'
import VuetifyConfirm from 'vuetify-confirm'
//import 'vuetify/dist/vuetify.min.css'
import '../assets/stylus/index.styl'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

const installToast = {
  install (Vue) {
    Vue.prototype.$toast = VuetifyToast
  }
}

export default () => {
  Vue.use(Vuetify, {
    iconfont: 'mdi',
    theme: {
      primary: '#2196F3',
      secondary: '#28C5AA',
      accent: '#3F3E40',
      error: '#EB5E5E',
      info: '#2894c5',
      success: '#28c5aa',
      warning: '#f1c644',
      google: '#5a86e4',
      grey: '#888888',
      intercom: '#286efa'
    },
    options: {
      customProperties: true,
    }
  })
  Vue.use(installToast)
  Vue.use(VuetifyConfirm, {
    buttonTrueText: 'OK',
    buttonFalseText: "No",
    color: 'warning',
    icon: 'warning',
    title: 'Warning',
    width: 300,
    property: '$confirm'
  })
}