import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _9924c48a = () => interopDefault(import('../pages/ambassador.vue' /* webpackChunkName: "pages/ambassador" */))
const _d004a804 = () => interopDefault(import('../pages/ambassador/index.vue' /* webpackChunkName: "pages/ambassador/index" */))
const _61a54f15 = () => interopDefault(import('../pages/ambassador/login.vue' /* webpackChunkName: "pages/ambassador/login" */))
const _082fb538 = () => interopDefault(import('../pages/ambassador/contacts/create.vue' /* webpackChunkName: "pages/ambassador/contacts/create" */))
const _8aeaccfc = () => interopDefault(import('../pages/ambassador/contacts/_id.vue' /* webpackChunkName: "pages/ambassador/contacts/_id" */))
const _62cc0504 = () => interopDefault(import('../pages/shipstation.vue' /* webpackChunkName: "pages/shipstation" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  window.history.scrollRestoration = 'manual'
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected
  if (to.matched.length < 2) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/ambassador",
      component: _9924c48a,
      children: [{
        path: "",
        component: _d004a804,
        name: "ambassador"
      }, {
        path: "login",
        component: _61a54f15,
        name: "ambassador-login"
      }, {
        path: "contacts/create",
        component: _082fb538,
        name: "ambassador-contacts-create"
      }, {
        path: "contacts/:id?",
        component: _8aeaccfc,
        name: "ambassador-contacts-id"
      }]
    }, {
      path: "/shipstation",
      component: _62cc0504,
      name: "shipstation"
    }, {
      path: "/",
      component: _0547af50,
      name: "index"
    }],

    fallback: false
  })
}
